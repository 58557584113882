<template>
  <div class="doctorInfo_container global_full_screen">
    <!-- <itemScroll ref="itemScroll"> -->
    <div>
      <div class="doctorInfo_row">
        <div class="doctorInfo_key">
          <span class="global_red_point">*</span>身份证号
        </div>
        <div class="doctorInfo_value global_text_input">
          <el-input
            v-model="data.id_card_number"
            placeholder="请输入身份证号"
            :disabled="!modifyShow['id_card_number'].content"
          ></el-input>

          <el-button
            size="small"
            v-if="
              !modifyShow['id_card_number'].isUpdate &&
              modifyShow['id_card_number'].button
            "
            @click="modify('id_card_number')"
            >修改</el-button
          >
        </div>
        <div
          class="update_button_group"
          v-if="modifyShow['id_card_number'].isUpdate"
        >
          <div class="update_button" @click="cancleUpdate('id_card_number')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('id_card_number')">
            保存
          </div>
        </div>
      </div>
      <div class="doctorInfo_row">
        <div
          class="doctorInfo_key"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div><span class="global_red_point">*</span>身份证照片</div>
          <el-button
            size="small"
            v-if="!modifyShow['idCard'].isUpdate && modifyShow['idCard'].button"
            @click="modify('idCard')"
            >修改</el-button
          >
        </div>

        <div class="doctorInfo_value">
          <div class="doctorInfo_card_front doctorInfo_card">
            <div class="doctorInfo_card_none">
              <div
                v-if="!data.id_card_front"
                class="doctorInfo_card_none_img_block"
              >
                <img
                  @click="selectFile('id_card_front')"
                  class="doctorInfo_card_none_img"
                  :src="require('@/assets/images/challenge/idCardFront.png')"
                  alt=""
                />
                <span>身份证正面</span>
              </div>
              <img
                @click="selectFile('id_card_front')"
                class="doctorInfo_card_img"
                @load="initScroll"
                v-else-if="!data.id_card_front.includes('*')"
                :src="data.id_card_front"
                alt=""
              />
              <div
                :class="{ red_text: data.id_card_audit === 1 }"
                class="doctorInfo_card_already_update global_container_center"
                v-else
              >
                {{ data.id_card_audit === 1 ? "请重新上传" : "已上传" }}
              </div>
            </div>
          </div>
          <div class="doctorInfo_card_backend doctorInfo_card">
            <div class="doctorInfo_card_none">
              <div
                v-if="!data.id_card_back"
                class="doctorInfo_card_none_img_block"
              >
                <img
                  @click="selectFile('id_card_back')"
                  class="doctorInfo_card_none_img"
                  :src="require('@/assets/images/challenge/idCardback.png')"
                  alt=""
                />
                <span>身份证反面</span>
              </div>
              <img
                class="doctorInfo_card_img"
                @load="initScroll"
                v-else-if="!data.id_card_back.includes('*')"
                :src="data.id_card_back"
                alt=""
                @click="selectFile('id_card_back')"
              />
              <div
                :class="{ red_text: data.id_card_audit === 1 }"
                class="doctorInfo_card_already_update global_container_center"
                v-else
              >
                {{ data.id_card_audit === 1 ? "请重新上传" : "已上传" }}
              </div>
            </div>
          </div>
        </div>
        <div class="update_button_group" v-if="modifyShow['idCard'].isUpdate">
          <div class="update_button" @click="cancleUpdate('idCard')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('idCard')">
            保存
          </div>
        </div>
      </div>
      <div class="doctorInfo_row">
        <div class="doctorInfo_key">
          <span class="global_red_point">*</span>银行卡号
        </div>
        <div class="doctorInfo_value global_text_input">
          <el-input
            v-model="data.bank_number"
            placeholder="请输入银行卡号"
            :disabled="!modifyShow['bank_number'].content"
          ></el-input>

          <el-button
            size="small"
            v-if="
              !modifyShow['bank_number'].isUpdate &&
              modifyShow['bank_number'].button
            "
            @click="modify('bank_number')"
            >修改</el-button
          >
        </div>
        <div
          class="update_button_group"
          v-if="modifyShow['bank_number'].isUpdate"
        >
          <div class="update_button" @click="cancleUpdate('bank_number')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('bank_number')">
            保存
          </div>
        </div>
      </div>
      <div class="doctorInfo_row">
        <div class="doctorInfo_key">
          <span class="global_red_point">*</span>开户银行<span
            class="doctorInfo_text_gray"
            >（示例：交通银行xx支行/营业部）</span
          >
        </div>
        <div class="doctorInfo_value global_text_input">
          <el-input
            v-model="data.bank"
            placeholder="请输入开户银行"
            :disabled="!modifyShow['bank'].content"
          ></el-input>

          <el-button
            size="small"
            v-if="!modifyShow['bank'].isUpdate && modifyShow['bank'].button"
            @click="modify('bank')"
            >修改</el-button
          >
        </div>
        <div class="update_button_group" v-if="modifyShow['bank'].isUpdate">
          <div class="update_button" @click="cancleUpdate('bank')">
            取消修改
          </div>
          <div class="update_button" @click="doCommitSingle('bank')">保存</div>
        </div>
      </div>

      <div class="doctorInfo_row doctorInfo_button_group">
        <customButton class="doctorInfo_button" size="small" @click="doCommit">
          <div class="doctorInfo_button_info">
            <img :src="require('@/assets/images/icon/apply.png')" alt="" />
            <span>确认提交</span>
          </div>
        </customButton>
      </div>
    </div>
    <!-- </itemScroll> -->
    <input
      type="file"
      @change="changeFile"
      ref="selectFile"
      v-if="manage.resetFile"
      v-show="false"
    />
    <imageCropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="300"
      :CropHeight="189"
      :flexScale="[8.56, 5.4]"
      :isShowHeadimgCalibration="manage.key == 'profile'"
      v-if="manage.url"
    ></imageCropper>

    <popup :cfg="popupCfg" v-if="isPoup">
      <div class="doctorInfo_tip_content">
        <div class="doctorInfo_tip_content_text">
          您是否确认上述信息是否正确
        </div>
        <div class="doctorInfo_add_page">
          <customButton class="doctorInfo_help_button" @click="doSubmit()">
            确认
          </customButton>
          <customButton
            class="doctorInfo_help_button"
            @click="hideSubmitPoup()"
          >
            取消
          </customButton>
        </div>
      </div>
    </popup>
    <popup :cfg="popupTipsCfg" v-if="isTipsPoup">
      <div class="doctorInfo_tip_content">
        <div class="doctorInfo_tip_content_text">
          此步骤可即时或延迟签署，任务进度将保留180天
        </div>
        <div class="doctorInfo_add_page">
          <customButton
            class="doctorInfo_help_button"
            @click="isTipsPoup = false"
          >
            确认签署
          </customButton>
          <customButton class="doctorInfo_help_button" @click="doColse">
            延迟签署
          </customButton>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import { ElLoading, ElInput, ElButton } from "element-plus";
import customButton from "@/components/unit/customButton";
// import itemScroll from "@/components/unit/itemScroll";
import imageCropper from "@/components/unit/imageCropper";
import popup from "@/components/unit/popup";
import weixin from "@/common/weixin";
export default {
  name: "doctorInfo",
  components: {
    // itemScroll: itemScroll,
    customButton: customButton,
    imageCropper: imageCropper,
    ElInput,
    ElButton,
    popup,
  },
  data() {
    return {
      popupCfg: {
        title: "",
        content: "",
        button: [],
      },
      popupTipsCfg: {
        title: "温馨提示",
        content: "",
        button: [],
      },
      isPoup: false,
      isTipsPoup: false,
      projectInfo: {},
      data: {
        id_card_number: "",
        bank: "",
        bank_number: "",
        id_card_front: "",
        id_card_back: "",
        id_card_audit: null,
      },
      splitReg: /[,\n]+/g,
      manage: {
        key: "",
        resetFile: true,
        url: "",
      },
      config: {
        showPlaceholder: true,
        placeholder:
          "示例：<br>河南省医学会呼吸病学分会间质性肺疾病学组委员会<br>河南省医学会变态反应分会感染学组委员<br>河南省呼吸与危重症学会间质性肺疾病学分会副主任委员",
      },
      hasPage: false,
      clickTime: 0,

      modifyField: ["id_card_number", "bank", "bank_number", "idCard"],
      cacheDataInfo: {},
      modifyShow: {
        id_card_number: {
          content: false,
          button: true,
          isUpdate: false,
        },
        bank: {
          content: false,
          button: true,
          isUpdate: false,
        },
        bank_number: {
          content: false,
          button: true,
          isUpdate: false,
        },
        idCard: {
          content: false,
          button: true,
          isUpdate: false,
        },
        project_id: 1,
        // id_card_front: {
        //   content: false,
        //   button: true,
        //   isUpdate: false,
        // },
        // id_card_back: {
        //   content: false,
        //   button: true,
        //   isUpdate: false,
        // },
      },
    };
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "个人信息";
    this.loadData();
    // this.hasDoctorPage();
    this.loadProjectInfo();
    this.project_id = this.$config.projectId;
  },
  methods: {
    isModifyShow() {
      let canotEmptySingleFields = ["id_card_number", "bank", "bank_number"];
      for (const key of canotEmptySingleFields) {
        if (this.data[key] == "") {
          this.modifyShow[key].content = true;
          this.modifyShow[key].button = false;
        }
      }
      if (!(this.data.id_card_front && this.data.id_card_back)) {
        this.modifyShow["idCard"].content = true;
        this.modifyShow["idCard"].button = false;
      }
    },

    setFocus() {
      this.config.showPlaceholder = false;
      this.$refs.doctorjobInput.focus();
    },
    onblur() {
      this.$refs.doctorjobInput.blur();
      this.config.showPlaceholder = true;
    },
    onfocus() {
      this.config.showPlaceholder = false;
    },

    loadProjectInfo() {
      let url = this.$tools.getURL(this.$urls.admin.project, {
        project_id: this.$config.projectId,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.projectInfo = res.data;
        })
        .catch((err) => {});
    },

    async loadData() {
      let url = this.$tools.getURL(
        this.$urls.admin.doctorInfo,
        this.$route.query
      );
      await this.$axios.get(url).then((res) => {
        this.data = res.data;

        if (
          this.data.sign_name == "" &&
          this.data.audit_status == 20 &&
          !this.initData()
        ) {
          this.isTipsPoup = true;
        }
        if (this.data.id_card_audit == 1) {
          this.data.id_card_back = "";
          this.data.id_card_front = "";
        }
        this.isModifyShow();
        for (let k in this.data) {
          if (this.data[k] == null) {
            this.data[k] = "";
          }
        }
        this.data.resume_academic = this.$tools
          .trimEnter(this.data.resume_academic)
          .replace(this.splitReg, "\n");

        setTimeout(() => {
          // this.initScroll();
        }, 2000);
      });
    },
    initData() {
      let verifyList = [
        "id_card_number",
        "bank",
        "bank_number",
        "id_card_front",
        "id_card_back",
      ];
      for (let index in verifyList) {
        let key = verifyList[index];
        if (!this.data[key]) {
          return false;
        }
      }
      return true;
    },
    initScroll() {
      // this.$refs.itemScroll.initScroll();
    },
    hasDoctorPage() {
      let adminInfo = this.$store.state.common.adminInfo;
      let doctorID = this.$route.query.id;
      let url = this.$tools.getURL(this.$urls.doctor.hasPage, {
        id: doctorID,
        phone: adminInfo.phone,
        project_id: this.project_id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.hasPage = Boolean(res.data);
        })
        .catch((err) => {});
    },
    selectFile(key) {
      if (this.loading) return;

      this.manage.key = key;
      this.$refs.selectFile.click();
      // this.loading = true;
      // // this.receiveData({url:"https://kclass.imedunion.com/image/2/132/card/8e81d9522a8364539525c947a538e6ed_new.png"})
      // weixin
      //   .selectFile(1, 999999)
      //   .then((files) => {
      //     setTimeout(() => {
      //       this.loading = false;
      //     }, 1000);
      //     // this.data.doctorHeadImg = files[0]
      //     this.manage.url = files[0];
      //     this.$nextTick(() => {
      //       this.$refs.imageCropper.setData(this.manage.url);
      //     });
      //   })
      //   .catch((err) => {
      //     setTimeout(() => {
      //       this.loading = false;
      //     }, 1000);
      //   });
    },
    changeFile(event) {
      let file = event.target.files[0];
      if (!file) return;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (item) => {
        this.manage.url = item.target.result;
        this.manage.resetFile = false;
        this.$nextTick(() => {
          this.manage.resetFile = true;
          this.$refs.imageCropper.setData(this.manage.url);
        });
      };
    },
    receiveData(data) {
      this.manage.url = "";
      if (this.manage.key == "profile") {
        this.dealwithProfile(data.url);
      } else {
        this.data[this.manage.key] = data.url;
      }
    },

    dealwithProfile(url) {
      let loading = this.$tips.customLoading();
      let data = {
        profile: url,
      };
      this.$axios
        .post(this.$urls.doctor.image, data)
        .then((res) => {
          this.data[this.manage.key] = res.profile;
          this.manage.url = "";
          loading.close && loading.close();
          loading = null;
        })
        .catch((err) => {
          loading.close && loading.close();
          loading = null;
        });
    },
    isHide(key) {
      return String(this.data[key] || "").includes("*");
    },
    verifyData() {
      if (this.projectInfo.sign_status == 1) {
        if (!this.data.id_card_number && !this.isHide("id_card_number")) {
          this.$tips.error({ text: "请输入身份证号" });
          return false;
        }
        let reg =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
        if (
          !reg.test(this.data.id_card_number) &&
          !this.isHide("id_card_number")
        ) {
          this.$tips.error({ text: "身份证号不合法" });
          return false;
        }
        if (!this.data.id_card_front) {
          this.$tips.error({ text: "请上传身份证正面" });
          return false;
        }
        if (!this.data.id_card_back) {
          this.$tips.error({ text: "请上传身份证反面" });
          return false;
        }
        if (!this.data.bank) {
          this.$tips.error({ text: "请输入开户银行" });
          return false;
        }
        if (!this.data.bank_number && !this.isHide("bank_number")) {
          this.$tips.error({ text: "请输入银行账号" });
          return false;
        }
        if (
          !/^\d{15,30}$/.test(
            this.data.bank_number
          ) &&
          !this.isHide("bank_number")
        ) {
          this.$tips.error({ text: "银行卡号错误,请重新输入" });
          return false;
        }
      }

      return true;
    },
    doCommit() {
      if (this.clickTime + 1500 > Date.now()) return;
      this.clickTime = Date.now();
      if (this.loading || !this.verifyData()) return;
      this.isPoup = true;
    },
    doSubmit() {
      this.loading = true;
      const loadingPage = ElLoading.service({
        lock: true,
        text: "资料上传中,请稍后",
        background: "rgba(255, 255, 255, 0.5)",
      });

      this.$axios
        .patch(this.$urls.admin.doctorInfo, {
          ...this.data,
          project_id: this.$tools.getStorage("projectId", sessionStorage),
        })
        .then((res) => {
          this.$tips.success({ text: "完善成功" });
          loadingPage.close();

          if (
            this.data.sign_name == "" &&
            this.data.audit_status == 20 &&
            this.$route.query?.meetId
          ) {
            this.$router.push({
              path: `/agreement/${this.$route.query.meetId}/${this.data.id}`,
            });
          } else {
            this.$router.back();
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
          loadingPage.close();
          this.isPoup = false;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    hideSubmitPoup() {
      this.isPoup = false;
    },
    checkData(key = "") {
      //  "id_card_number",
      // "bank",
      // "bank_number",
      // "id_card_front",
      // "id_card_back",
      if (
        (key == "" || key == "id_card_number") &&
        !this.isHide(this.data.id_card_number)
      ) {
        if (!this.data.id_card_number) {
          this.$tips.error({ text: "请输入身份证号" });
          return false;
        }
        let reg =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
        if (!reg.test(this.data.id_card_number)) {
          this.$tips.error({ text: "身份证号不合法" });
          return false;
        }
      }
      if (key == "" || key == "idCard") {
        if (!this.data.id_card_front) {
          this.$tips.error({ text: "请上传身份证正面" });
          return false;
        }

        if (!this.data.id_card_back) {
          this.$tips.error({ text: "请上传身份证反面" });
          return false;
        }
      }

      if ((key == "" || key == "bank") && !this.data.bank) {
        this.$tips.error({ text: "请输入开户银行" });
        return false;
      }
      if (
        (key == "" || key == "bank_number") &&
        !this.isHide(this.data.bank_number)
      ) {
        if (!this.data.bank_number) {
          this.$tips.error({ text: "请输入银行账号" });
          return false;
        }
        if (
          !/^\d{15,30}$/.test(
            this.data.bank_number
          ) &&
          !this.isHide("bank_number")
        ) {
          this.$tips.error({ text: "银行卡号错误,请重新输入" });
          return false;
        }
      }

      return true;
    },
    //只更改某一单独字段的值
    doCommitSingle(key) {
      if (!this.checkData(key) || this.isloading) return;
      let data = {
        id: this.data.id,
        project_id: this.project_id,
      };

      if (key == "idCard") {
        data["id_card_front"] = this.data["id_card_front"];
        data["id_card_back"] = this.data["id_card_back"];
      } else {
        data[key] = this.data[key];
      }

      const loadingPage = ElLoading.service({
        lock: true,
        text: "资料上传中,请稍后",
        background: "rgba(255, 255, 255, 0.5)",
      });
      this.$axios
        .patch(this.$urls.admin.doctorInfo, data)
        .then(() => {
          setTimeout(() => {
            loadingPage.close();
            this.modifyShow[key].content = false;
            this.modifyShow[key].isUpdate = false;
            // this.$refs.itemScroll.initScroll();
          }, 1000);
          this.$tips.success({ text: "修改成功" });
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            loadingPage.close();
          }, 1000);
        });
    },
    //取消修改
    cancleUpdate(key) {
      this.modifyShow[key].content = false;
      this.modifyShow[key].isUpdate = false;
      for (const doctorKey of this.modifyField) {
        if (doctorKey == "idCard") {
          this.data["id_card_front"] = this.cacheDataInfo["id_card_front"];
          this.data["id_card_back"] = this.cacheDataInfo["id_card_back"];
        } else {
          this.data[doctorKey] = this.cacheDataInfo[doctorKey];
        }
      }
      // this.$refs.itemScroll.initScroll();
    },
    modify(key) {
      for (const doctorKey of this.modifyField) {
        if (doctorKey == "idCard") {
          this.cacheDataInfo["id_card_front"] = this.data["id_card_front"];
          this.cacheDataInfo["id_card_back"] = this.data["id_card_back"];
        } else {
          this.cacheDataInfo[doctorKey] = this.data[doctorKey];
        }
      }
      this.modifyShow[key].content = !this.modifyShow[key].content;
      this.modifyShow[key].isUpdate = true;

      if (key == "idCard") {
        this.data["id_card_back"] = "";
        this.data["id_card_front"] = "";
      } else {
        console.log("key :>> ", key);
        this.data[key] = "";
      }
      // this.$refs.itemScroll.initScroll();
    },
    doColse() {
      let type = this.$tools.getStorage("type", sessionStorage) || ""; //进入签署协议的方式 列表进1  详情进2 //公众号进
      console.log("type :>> ", type);
      if (!type) {
        weixin.wx.closeWindow();
        return;
      } else if (type == 2) {
        this.$router.push({
          path: "/meetDetail",
          query: {
            project_id: this.$config.projectId,
            id: this.$route.query.meetId,
          },
        });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style >
.doctorInfo_card_already_update .red_text {
  color: #f00;
}
.doctorInfo_container {
  position: relative;
  padding: 4vw;
  background: #fff;
  overflow: auto;
}
.doctorInfo_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2vw;
  position: relative;
}
.doctorInfo_key {
  margin-bottom: 2.4vw;
}
.doctorInfo_text_gray {
  color: #686868;
  font-size: 3.6vw;
}
.doctorInfo_card {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2.8vw 0;
}
.doctorInfo_card_none {
  width: 80vw;
  /* height: 57vw; */
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.doctorInfo_card_none_img_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.doctorInfo_card_none_img_block span {
  color: #ccc;
  margin-top: 1vw;
}
.doctorInfo_card_none_img {
  width: 80vw;
  /* width: 18.13vw; */
  height: auto;
}
.doctorInfo_card_img {
  width: 100%;
  height: 100%;
}
.doctorInfo_button_group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.doctorInfo_button {
  width: 74.66vw;
  height: 9.4vw;
}
.doctorInfo_button_info {
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.doctorInfo_button_info img {
  height: 5.1vw;
  width: auto;
  margin-right: 1vw;
}
.doctorInfo_value {
  border: 1px solid #e6e6e6;
  padding: 0 0 0 4vw;
}
.doctorjobInput_placeholder {
  font-size: 3.5vw;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 2vw;
  color: #c4c4cc;
}

.doctorInfo_card_already_update {
  color: #686868;
  height: 57vw;
}
.doctorInfo_container .el-button {
  border: 1px solid #0047ea;
  border-radius: 1vw;
}
.doctorInfo_tip_content {
  padding: 4vw 0;
}
.doctorInfo_tip_content_text {
  display: flex;
  align-items: center;
  min-height: 16vw;
  padding: 0 5vw;
}
.popup_title {
}
.doctorInfo_add_page {
  display: flex;
  justify-content: center;
  margin-top: 2vw;
}
.doctorInfo_add_page .doctorInfo_help_button {
  flex: 30% 0 0;
  padding: 2vw 0;
}
.doctorInfo_add_page .doctorInfo_help_button:first-child {
  margin-right: 5vw;
}
.update_button_group {
  padding: 2vw;
  display: flex;
  justify-content: flex-end;
}
.update_button_group :first-child {
  margin-right: 4vw;
}
.update_button {
  padding: 1vw 4vw;
  border-radius: 2vw;
  text-align: center;
  border: 1px solid #0047ea;
  color: #000;
}
</style>
